import React, {FC} from "react";
import {Container, ContainerRow, LinkElem} from "./common-error-text-styles";

export const CommonErrorText: FC = () => {
    return <Container>
        <ContainerRow>
            Произошла непредвиденная ошибка. Разработчики уже в курсе и занимаются исправлением.
            <br/> Пожалуйста, попробуйте ещё раз. <br/>
            Если ошибка будет повторяться — пожалуйста, сообщите нам в техподдержку:
        </ContainerRow>
        <ContainerRow>
            <LinkElem target={"_blank"} href={'tel:+74957401212'}>
                +7(495)740-12-12
            </LinkElem>
            <LinkElem target={"_blank"} href={'mailto:support@correct.su'}>
                support@correct.su
            </LinkElem>
            <LinkElem target={"_blank"} href={'https://tlgg.ru/@CORRECT_support_bot'}>
                t.me/CORRECT_Support_bot
            </LinkElem>
        </ContainerRow>
    </Container>
}
