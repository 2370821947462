import React, {useCallback} from "react";
import styles from "./dropzone.module.scss";
import {useDropzone} from "react-dropzone";
import {IFileFromInput} from "../../ducks/upload";
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';

export interface IDropzoneProps {
    isEmpty?: boolean;
    files: IFileFromInput[];
    setFiles: (files: IFileFromInput[]) => void;
    onDropHandler: (params: any) => void;
}

export default function MyDropzone(props: IDropzoneProps) {
    const onDrop = useCallback(props.onDropHandler, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop,
        noDrag: true,
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/tiff': ['.tiff', '.tif'],
            'application/pdf': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
        }
    })
    return (
        <div className={`${styles.dropZone}`} {...getRootProps()}>
            <input
                {...getInputProps()}
            />
            {props.isEmpty && <div className={styles.dropZoneTitle}>Загрузите акты сверки для поиска расхождений</div>}
            <div>
                {!props.isEmpty && <div className={styles.dropZoneTitleNotEmpty}>Файлы загружены <CheckCircleOutlineSharpIcon
                    sx={{
                        width: 30,
                        height: 30
                    }}
                    color={'success'}
                    />
                </div>}
            </div>
            <div className={`${styles.uploadTextContainer} ${styles.marginZero}`}>
                {props.isEmpty && <>
                    <div className={styles.dropZoneLabel}>Кликните сюда для загрузки файлов</div>
                    <div className={ styles.dropZoneLabel}>или перетащите файлы сюда</div>
                </>}
                {!props.isEmpty && <>
                    <div className={styles.smallLabel}>Отправьте документы на обработку</div>
                    <div className={ styles.smallLabel}>или загрузите еще</div>
                </>}
                <div className={styles.imageIcon}/>
            </div>
            {props.isEmpty && <div className={styles.dropZoneFooter}>Поддерживаются следующие файлы: PDF, JPG/JPEG, PNG, TIFF</div>}
        </div>
    )
}
