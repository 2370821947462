import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import styles from "./DifferenceDocument.module.scss";
import {Paper} from "@mui/material";
import {IDifference} from "../../api/monitoringApi";
import {getObjectFitSize} from "../../helper/other";
import {isIE} from "rsuite/utils";

const documentContainerWidth = 900;
const documentContainerHeight = 990;

export enum PageParts {
    Right = "Right",
    Left = "Left",
    Comment = "Comment",
}

export interface IDifferenceItem {
    keyName: string;
    width: number;
    height: number;
    left: number;
    top: number;
    isHidden?: boolean;
}

export interface IDifferenceMock {
    imageSrc: string;
    diffs: IDifferenceItem[];
    text: string;
}

export interface DifferenceDocumentProps {
    imageSrc: string;
    items: IDifference[];
    firstDetailedDiff: string;
    pageIndex: number;
    scale: number;
    side: PageParts,
    detailedDiff: string | undefined;
    setDetailedDiff: (diff: string | undefined) => void;
    setScale: (scale: number) => void;
    zoom: number;
    rotate: number;
    onLoad?: () => void;
    docContRef: React.RefObject<HTMLDivElement>
}

export interface IDifferenceDocumentState {
    isScrolling: boolean;
    clientX: number;
    clientY: number;
    scrollX: number;
    scrollY: number;
}

export function DifferenceDocument(props: DifferenceDocumentProps) {
    const imageRef: React.RefObject<HTMLImageElement> = useRef();
    const [isLoaded, setIsLoaded] = useState(undefined);
    let imageObject = {
        width: 0,
        height: 0,
        x: 0,
        y: 0
    }
    let rateWidth = 0;
    let rateHeight = 0;
    let offsetY = 0;
    let offsetX = 0;

    if (imageRef && imageRef.current) {
        const htmlImgElem = imageRef.current;
        const detectIsIE = isIE();
        const {width, height} = detectIsIE ? {width: documentContainerWidth, height: documentContainerHeight} : {width: imageObject.width, height: imageObject.height};
        const imageSizes = getObjectFitSize(true, documentContainerWidth, documentContainerHeight, htmlImgElem.naturalWidth, htmlImgElem.naturalHeight);
        imageObject = detectIsIE ? {width: props.scale * width, height: props.scale * height, y: 0, x: 0} : {...imageSizes};
        rateWidth = (detectIsIE ? width : imageObject.width) / htmlImgElem.naturalWidth;
        rateHeight = (detectIsIE ? height : imageObject.height) / htmlImgElem.naturalHeight;
        offsetY = imageObject.y;
        offsetX = imageObject.x;
    }

    useEffect(() => {
        props.setScale(2)
        try {
            props.side === PageParts.Right && props.setDetailedDiff(`${props.firstDetailedDiff}`)
            const discrepancy = document.getElementById( `${props.side}${props.firstDetailedDiff}`)
            if (discrepancy) {
                discrepancy.scrollIntoView({inline: 'center', block: 'center'})
            }
        }
        catch {

        }
        return () => props.setScale(1)
    }, [isLoaded])


    const onWheel = (event) => {
        event.stopPropagation();

        event.nativeEvent.stopImmediatePropagation();
        //@ts-ignore
        let delta = event.deltaY || event.detail || event.wheelDelta;
        if (delta < 0) {
            props.setScale(props.scale + 0.4)
        } else {
            if (props.scale < 1.2) {
                return
            }
            props.setScale(props.scale - 0.4)
        }

        const ratio = (delta < 0 ? ((props.scale + 0.4) / props.scale) : ((props.scale - 0.4) / props.scale))
        const pagesCont = document.getElementById('pagesContainer')
        const offsetTop = event.clientY - pagesCont.offsetTop
        const docCont: any = props.docContRef?.current
        if (props.side === 'Left') {
            const docLeft = docCont.scrollLeft = (docCont.scrollLeft + event.clientX) * ratio - event.clientX;
            const docTop =  docCont.scrollTop = (docCont.scrollTop + offsetTop) * ratio - offsetTop;
            docCont.scrollLeft = docLeft;
            docCont.scrollTop = docTop;
            setTimeout(() => {
                docCont.scrollLeft = docLeft;
                docCont.scrollTop = docTop;
            }, 0);
        } else {
            const rowItemCont = document.getElementById('rowItemContainerRight');
            const offset = rowItemCont.offsetLeft;
            const docLeft = (docCont.scrollLeft + event.clientX - offset) * ratio - event.clientX + offset;
            const docTop =  (docCont.scrollTop + offsetTop) * ratio - offsetTop;
            docCont.scrollLeft = docLeft;
            docCont.scrollTop = docTop;
            setTimeout(() => {
                docCont.scrollLeft = docLeft;
                docCont.scrollTop = docTop;
            }, 0);
        }
    }

    useLayoutEffect(() => {
        if (props.detailedDiff) {
            const discrepancy = document.getElementById( `${props.side}${props.detailedDiff}`)
            if(discrepancy){
               setTimeout(() => {
                   discrepancy.scrollIntoView({inline: 'center', block: 'center'});
               }, 30);
           }
        }

    }, [props.detailedDiff]);

    const getRotateInverted = (value: number): { inverted: string | undefined, rotate: string | undefined } => {
        let rotate;
        let inverted;

        switch (value) {
            case 90:
                inverted = styles.rotateHorizontal
                rotate = styles.rotate90;
                break;
            case 180:
                inverted = undefined;
                rotate = styles.rotate180;
                break;
            case 270:
                inverted = styles.rotateHorizontal
                rotate = styles.rotate270;
                break;
            case -90:
                inverted = styles.rotateHorizontal
                rotate = styles.rotateMinus90;
                break;
            case -180:
                inverted = undefined;
                rotate = styles.rotateMinus180;
                break;
            case -270:
                rotate = styles.rotateMinus270;
                inverted = styles.rotateHorizontal
                break;
            default:
                inverted = undefined;
                rotate = undefined;
                break;
        }

        return {
            inverted,
            rotate,
        }
    }

    return <Paper
        draggable={false}
        onWheel={onWheel}
        key={`image_paper_${props.side}${props.pageIndex}`}
        style={{
            zoom: props.zoom,
        }}
        elevation={0}
        id={'pdfDoc'}
        className={`${styles.pdfFile} ${getRotateInverted(props.rotate).rotate}`}
    >
        <div
            draggable={false}
            id={`pageContainer${props.side}${props.pageIndex}`}
            className={`${styles.pageContainer} `}
        >
            <img
                draggable={false}
                ref={imageRef}
                onLoad={(event) => {
                    setIsLoaded(true)
                }}
                id={'docImage'}
                alt={"Uploading error"}
                style={{
                    width: `${(props.scale * documentContainerWidth).toFixed(1)}px`,
                    height: `${(props.scale  * documentContainerHeight).toFixed(1)}px`,
                    objectFit: `contain`,
                }}
                src={props.imageSrc}
            />
            {isLoaded && props.items.map((el,indexEl) => (
                <div
                    draggable={false}
                    id={`${props.side}${el.apiIndex}`}
                    key={`${el.rowId}${props.side}${el.discrepancyIndex}${el.apiIndex}}${indexEl}`}
                    onMouseDown={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        props.setDetailedDiff(`${el.apiIndex}`);
                    }}
                    style={{
                        top: `${((props.scale  * (el.location.topOffset) * rateHeight) + (props.scale * offsetY)) + 2 * (props.scale)
                        }px`,
                        left: `${((props.scale  * (el.location.leftOffset) * rateWidth) + (props.scale  * offsetX))+ 2 * props.scale 

                        }px`,
                        width: `${(props.scale  * (el.location.width) * rateWidth) - 4 * props.scale 

                        }px`,
                        height: `${(props.scale  * rateHeight * (el.location.height ))- 4 * props.scale 
                        }px`,
                    }}
                    className={`${styles.polyLine} ${ (props.detailedDiff === `${el.apiIndex}`) && styles.selectedItem}`}
                >
                </div>
            ))}
        </div>
    </Paper>

}
