import {Thunk, TypedAction} from "../store/types";
import {
    selectPackageFromStorage,
    selectTechReportText,
    selectUploadFiles,
    selectUploadPackage
} from "../selectors/upload";
import {getUploadApiObj, IAddedFile, IConvertedImage, IImagesByPackageId, IPackage} from "../api/uploadApi";
import {getToken, PACKAGE_ID_STORAGE_NAME, PACKAGE_TTL, setLocaleStorageItem} from "../helper/other";
import {popupPush, spinnerMinusStatus, spinnerPlusStatus} from "./popup";
import {push} from "connected-react-router";
import {ROUTES} from "../app/Routes";
import {getMonitoringApiObj, ITechReport} from "../api/monitoringApi";
import {selectUrlId} from "../selectors/other";
import {selectDetailedDocuments} from "../selectors/monitoring";

const UPDATE_UPLOAD_FILES = "UPLOAD/UPDATE_UPLOAD_FILES";
const UPDATE_TECH_REPORT_TEXT = "UPLOAD/UPDATE_TECH_REPORT_TEXT";
const SET_DETAILED_UPLOAD = "UPLOAD/SET_DETAILED_UPLOAD";
const SET_UPLOAD_IS_LOADING = "UPLOAD/SET_UPLOAD_IS_LOADING";
const SET_UPLOAD_PAGE_COUNT = "UPLOAD/SET_UPLOAD_PAGE_COUNT";
const SET_DETAILED_FILE_PAGE = "UPLOAD/SET_DETAILED_FILE_PAGE";
const SET_SELECTED_FILE_IDS = "UPLOAD/SET_SELECTED_FILE_IDS";
const SET_UPLOAD_POPUP_OPEN = "UPLOAD/SET_UPLOAD_POPUP_OPEN";
const UPDATE_UPLOAD_PACKAGE = "UPLOAD/UPDATE_UPLOAD_PACKAGE";
const SHOW_PREVIEW_PAGES = "UPLOAD/SHOW_PREVIEW_PAGES";

export type UploadActions =
    | TypedAction<typeof UPDATE_UPLOAD_FILES, IFileFromInput[]>
    | TypedAction<typeof SET_UPLOAD_PAGE_COUNT, number>
    | TypedAction<typeof SET_DETAILED_UPLOAD, IFileFromInput>
    | TypedAction<typeof SET_UPLOAD_IS_LOADING, boolean>
    | TypedAction<typeof SHOW_PREVIEW_PAGES, boolean>
    | TypedAction<typeof UPDATE_UPLOAD_PACKAGE, IPackage | undefined>
    | TypedAction<typeof SET_UPLOAD_POPUP_OPEN, boolean>
    | TypedAction<typeof SET_SELECTED_FILE_IDS, string>
    | TypedAction<typeof UPDATE_TECH_REPORT_TEXT, string>
    | TypedAction<typeof SET_DETAILED_FILE_PAGE, IDetailedDocumentPage | undefined>;

export interface IUploadState {
    files: IFileFromInput[];
    detailedFile: IDetailedFile | undefined;
    pageCount: number;
    selectedIds: string[];
    isLoading: boolean;
    showPreviewPages: boolean;
    page: IDetailedDocumentPage | undefined;
    isUploadPopupOpen: boolean;
    package: undefined;
    reportText: string;
}

export interface IDetailedDocumentPage {
    scale: number;
    rotate: number;
}

export interface IFileFromInput {
    id: string;
    fileUrl: string;
    fileName: string;
    imageId: number;
}

export interface IDetailedFile extends IFileFromInput {
    index: number;
}

export const initialState: IUploadState = {
    files: [],
    page: {
        scale: 1,
        rotate: 0,
    },
    selectedIds: [],
    detailedFile: undefined,
    pageCount: 0,
    isLoading: true,
    showPreviewPages: true,
    isUploadPopupOpen: false,
    package: undefined,
    reportText: "",
}

export function clearUploads() {
    return dispatch => {
        dispatch({type: UPDATE_UPLOAD_FILES, payload: []});
    }
}

export function setShowPreviewPages(value: boolean) {
    return dispatch => {
        dispatch({type: SHOW_PREVIEW_PAGES, payload: value});
    }
}

export function setSelectedIds(ids: string[]) {
    return dispatch => {
        dispatch({type: SET_SELECTED_FILE_IDS, payload: ids});
    }
}

const deletePackage = (dispatch, reload = false) => {
    dispatch({type: UPDATE_UPLOAD_PACKAGE, payload: undefined});
    setLocaleStorageItem(PACKAGE_ID_STORAGE_NAME, {package: undefined}, 1);
    reload && window.location.reload();
}

export function uploadFiles(uploads: IFileFromInput[], usePrevUploads: boolean = false): Thunk {
    return async (dispatch, getState) => {
        let errors = [];

        const addFilesIntoPackage = async (uploadPackage: IPackage) => {
            dispatch(spinnerPlusStatus())
            for (let i = 0; i < uploads.length; i++) {
                const upload = uploads[i];
                dispatch(spinnerPlusStatus());
                await getUploadApiObj()
                    .addFileToPackage({
                        title: uploadPackage.title,
                        packageId: uploadPackage.packageId,
                        rawBase64: upload.fileUrl.split('base64,')[1],
                    })
                    .then(res => res.json())
                    .then(async res => {
                        if(!res) return;
                        const addedFile: IAddedFile = res;
                        console.log("addedFile", addedFile);
                        if(addedFile?.imageIds) {
                            dispatch(
                                setUploads( [{
                                    fileUrl: ``,
                                    fileName: upload.fileName,
                                    id: `${new Date().getTime()}${i}`,
                                    imageId: 123,
                                }], true)
                            )
                        }
                    })
                    .catch((error) => {
                        let err = 'Произошла ошибка';
                        let code
                        try {
                            let message = JSON.parse(error.message);
                            err = message.message
                            code = message.code
                        } catch {

                        }
                        dispatch(popupPush({
                            actionTitle: "Хорошо",
                            isDefaultError: true,
                            actionVisible: true,
                            data: [err],
                            actionHandler: () => {
                                if (code == 410) {
                                    deletePackage(dispatch, true)
                                }
                            },
                            cancelVisible: false,
                        }))
                    })
                    .finally(() => {
                        console.log("errors", errors)
                        if (errors.length > 0) {
                            dispatch(popupPush({
                                actionTitle: "Хорошо",
                                actionVisible: true,
                                isDefaultError: true,
                                data: errors.map(elem => elem ? elem : "Произошла ошибка"),
                                actionHandler: () => {
                                },
                                cancelVisible: false,
                            }))
                        }
                        dispatch(spinnerMinusStatus())
                    });
                dispatch(spinnerMinusStatus())
            }
            dispatch(spinnerMinusStatus())
        }
        const detailedPackageFromState = getState().uploads.package;
        const detailedPackageStorage = selectPackageFromStorage();
        const detailedPackage = detailedPackageFromState
            ? detailedPackageFromState
            : detailedPackageStorage
                ? detailedPackageStorage
                : undefined;
        if (detailedPackage && (new Date().getTime() - detailedPackage.creationTime < PACKAGE_TTL || uploads.length)) {
            await addFilesIntoPackage(detailedPackage);
        } else {
            await getUploadApiObj()
                .createPackage({
                    title: 'docshow upload',
                    user: '',
                    dbId: ``,
                })
                .then(res => res.json())
                .then(async res => {
                    const uploadPackage: IPackage = {...res, creationTime: new Date().getTime()};
                    setLocaleStorageItem(PACKAGE_ID_STORAGE_NAME, {package: uploadPackage}, 8);
                    dispatch({type: UPDATE_UPLOAD_PACKAGE, payload: uploadPackage});
                    await addFilesIntoPackage(uploadPackage);
                })
                .catch((error) => {
                    let err = 'Произошла ошибка';
                    try {
                        let message = JSON.parse(error.message);
                        err = message.message
                    } catch {

                    }
                    dispatch(popupPush({
                        actionTitle: "Хорошо",
                        actionVisible: true,
                        data: [err],
                        isDefaultError: true,
                        actionHandler: () => {
                            deletePackage(dispatch, true)
                        },
                        cancelVisible: false,
                    }))
                })
                .finally(() => {
                    if (errors.length > 0) {
                        dispatch(popupPush({
                            actionTitle: "Хорошо",
                            actionVisible: true,
                            isDefaultError: true,
                            data: errors,
                            actionHandler: () => {
                            },
                            cancelVisible: false,
                        }))
                    }
                    dispatch(spinnerMinusStatus())
                })
        }
    }
}

export function getUploads(): Thunk {
    return async (dispatch, getState) => {
        const token = getToken();
        const detailedPackageFromState = getState().uploads.package;
        const detailedPackageStorage = selectPackageFromStorage();
        const detailedPackage: IPackage = detailedPackageStorage && !detailedPackageFromState ? detailedPackageStorage : undefined
        let images = [];
        if (detailedPackage && new Date().getTime() - detailedPackage.creationTime < PACKAGE_TTL) {
            dispatch(spinnerPlusStatus())
            await getUploadApiObj()
                .getImagesByPackageId(detailedPackage.packageId)
                .then(res => res.json())
                .then(async res => {
                    dispatch({type: UPDATE_UPLOAD_PACKAGE, payload: detailedPackage});
                    const addedFile: IImagesByPackageId = res;
                    for (let i = 0; i < addedFile.imgId.length; i++) {
                        const imageId = addedFile.imgId[i];
                        await getUploadApiObj()
                            .getImageById(imageId)
                            .then(response => response.json())
                            .then(response => {
                                const imageBase64: IConvertedImage = response;
                                images.push(imageBase64.rawBase64);
                                dispatch(
                                    setUploads([
                                        {
                                            fileUrl: `data:image/jpg;base64,${imageBase64.rawBase64}`,
                                            fileName: '',
                                            id: `${new Date().getTime()}${i}`,
                                            imageId: imageId,
                                        }
                                    ], true)
                                )
                            })
                            .catch((err) => {
                                console.log("err", err)
                            })
                            .finally(() => {
                                dispatch(spinnerMinusStatus())
                            })
                    }
                })
                .catch((err) => {
                    console.log("err", err)
                })
                .finally(() => {
                    dispatch(spinnerMinusStatus())
                })
            dispatch(spinnerMinusStatus())
        } else {
            deletePackage(dispatch)
        }
    }
}

export function setUploads(uploads: IFileFromInput[], usePrevUploads: boolean = false): Thunk {
    return (dispatch, getState) => {
        const prevUploads = selectUploadFiles(getState());
        if (uploads.length === 0) {
            dispatch({type: UPDATE_UPLOAD_PACKAGE, payload: undefined});
        }
        dispatch({type: UPDATE_UPLOAD_FILES, payload: usePrevUploads ? [...prevUploads, ...uploads] : [...uploads]});
    }
}

export function deleteUploads(newUploads: IFileFromInput[]): Thunk {
    return async (dispatch, getState) => {
        const packageDetailed: IPackage = selectUploadPackage(getState());
        dispatch(spinnerPlusStatus())
        if (newUploads.length > 0) {
            await getUploadApiObj()
                .sortPackage(packageDetailed.packageId, newUploads.map(elem => elem.imageId))
                .then(() => {
                    dispatch({type: UPDATE_UPLOAD_FILES, payload: newUploads});
                })
                .catch((err) => {
                    console.log('sort package', err)
                })
                .finally(() => {
                    dispatch(spinnerMinusStatus())
                })
        } else {
            dispatch({type: UPDATE_UPLOAD_PACKAGE, payload: undefined})
            dispatch({type: SET_DETAILED_UPLOAD, payload: undefined})
            dispatch({type: UPDATE_UPLOAD_FILES, payload: []});
            setLocaleStorageItem(PACKAGE_ID_STORAGE_NAME, undefined, 10);
        }
        dispatch(spinnerMinusStatus())
    }
}

export function getDifferenceProtocol(): Thunk {
    return async (dispatch, getState) => {
        const id = selectUrlId(getState())
        dispatch(spinnerPlusStatus())
        await getUploadApiObj()
            .getDifferenceProtocol(id as string)
            .then(res => res.blob())
            .then((res) => {
                const blob = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

                const link = document.createElement('a');
                const url = window.URL.createObjectURL(blob);

                // @ts-ignore
                link.href = url;
                link.download = `Акт разногласий.xlsx`;

                document.body.append(link);

                link.click();

                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
            })
            .catch((e) => {
                dispatch(popupPush({
                    actionTitle: "Хорошо",
                    actionVisible: true,
                    isDefaultError: true,
                    data: ["Произошла ошибка"],
                    actionHandler: () => {
                    },
                    cancelVisible: false,
                }))
                console.log('getDifferenceProtocol', e);
            })
        dispatch(spinnerMinusStatus())
    }
}

export function downloadPdf(): Thunk {
    return async (dispatch, getState) => {
        const id = selectUrlId(getState())
        dispatch(spinnerPlusStatus())
        await getUploadApiObj()
            .getCliche(id as string)
            .then(res => res.blob())
            .then((res) => {
                const blob = new Blob([res], {type: 'application/pdf'});
                const link = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                // @ts-ignore
                link.href = url;
                try {
                    const leftDocument = selectDetailedDocuments(getState())[0]
                    const {contractor, periodStart, periodEnd} = leftDocument?.docflowDocumentData?.extendData
                    link.download = `${contractor} за период ${periodStart.substring(0, 10)}-${periodEnd.substring(0, 10)} (c клише).pdf`;
                } catch (e) {
                    link.download = 'Клише.pdf';
                }
                document.body.append(link);
                link.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
            })
            .catch((e) => {
                dispatch(popupPush({
                    actionTitle: "Хорошо",
                    actionVisible: true,
                    data: ["Произошла ошибка"],
                    isDefaultError: true,
                    actionHandler: () => {
                    },
                    cancelVisible: false,
                }))
                console.log('downloadPdf', e);
            })
        dispatch(spinnerMinusStatus())
    }
}

export function sendTechnicalReport(props: ITechReport): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerPlusStatus())
        await getMonitoringApiObj()
            .sendTechnicalReport({...props, message: selectTechReportText(getState())})
            .then(() => {
                dispatch(popupPush({
                    actionTitle: "Хорошо",
                    actionVisible: true,
                    data: ["Информация отправлена в техподдержку, мы изучим проблему и ответим с 9:00 до 18:00 по московскому времени. Обычно мы отвечаем в течение одного рабочего дня."],
                    actionHandler: () => {
                    },
                    cancelVisible: false,
                }))
                dispatch({
                    type: UPDATE_TECH_REPORT_TEXT,
                    payload: "",
                })
            })
            .catch((err) => {
                try {
                    const error = JSON.parse(err);
                    dispatch(popupPush({
                        actionTitle: "Хорошо",
                        actionVisible: true,
                        data: [error.message ? error.message : "Произошла ошибка"],
                        isDefaultError: true,
                        actionHandler: () => {
                        },
                        cancelVisible: false,
                    }))
                    dispatch({
                        type: UPDATE_TECH_REPORT_TEXT,
                        payload: "",
                    })
                } catch (e) {
                    dispatch({
                        type: UPDATE_TECH_REPORT_TEXT,
                        payload: "",
                    })
                    dispatch(popupPush({
                        actionTitle: "Хорошо",
                        actionVisible: true,
                        data: ["Произошла ошибка"],
                        isDefaultError: true,
                        actionHandler: () => {
                        },
                        cancelVisible: false,
                    }))
                }

                console.log('sendTechnicalReport', err)
            })
            .finally(() => {
                dispatch(spinnerMinusStatus())
            })
        dispatch(spinnerMinusStatus())
    }
}

export function sendPackageToRecognition(isPairSearchSkip: boolean): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerPlusStatus())
        const detailedPackageStorage = selectPackageFromStorage();
        const detailedPackageFromState = selectUploadPackage(getState());
        const detailedPackage = detailedPackageFromState
            ? detailedPackageFromState
            : detailedPackageStorage
                ? detailedPackageStorage
                : undefined;
        if (detailedPackage && new Date().getTime() - detailedPackage.creationTime < PACKAGE_TTL) {
            await getUploadApiObj()
                .sendPackageToRecognition(detailedPackage.packageId, isPairSearchSkip)
                .then(() => {
                    dispatch({type: UPDATE_UPLOAD_PACKAGE, payload: undefined})
                    dispatch({type: SET_DETAILED_UPLOAD, payload: undefined})
                    dispatch({type: UPDATE_UPLOAD_FILES, payload: []});
                    dispatch({type: SET_UPLOAD_POPUP_OPEN, payload: false});
                    setLocaleStorageItem(PACKAGE_ID_STORAGE_NAME, undefined, 10);
                    dispatch(push(ROUTES.Home));
                })
                .catch((error) => {
                    console.log("sendPackageToRecognition", error)
                })
            dispatch(spinnerMinusStatus())
        }
    }
}

export function setDetailedUpload(uploadFile: IFileFromInput | undefined) {
    return dispatch => {
        dispatch({type: SET_DETAILED_UPLOAD, payload: uploadFile});
    }
}

export function onClearUploads() {
    return dispatch => {
        setLocaleStorageItem(PACKAGE_ID_STORAGE_NAME, undefined, 30)
        dispatch({type: UPDATE_UPLOAD_PACKAGE, payload: undefined})
        dispatch({type: SET_DETAILED_UPLOAD, payload: undefined})
        dispatch({type: UPDATE_UPLOAD_FILES, payload: []});
    }
}

export function setUploadPageCount(count: number) {
    return dispatch => {
        dispatch({type: SET_UPLOAD_PAGE_COUNT, payload: count});
    }
}

export function updateTechReportText(value: string) {
    return dispatch => {
        dispatch({type: UPDATE_TECH_REPORT_TEXT, payload: value});
    }
}

export function setDetailedFilePages(page: IDetailedDocumentPage | undefined) {
    return dispatch => {
        dispatch({type: SET_DETAILED_FILE_PAGE, payload: page});
    }
}

export function setUploadIsLoading(isLoading: boolean) {
    return dispatch => {
        dispatch({type: SET_UPLOAD_IS_LOADING, payload: isLoading});
    }
}

export function setUploadPopupOpen(isOpen: boolean) {
    return dispatch => {
        dispatch({type: SET_UPLOAD_POPUP_OPEN, payload: isOpen});
    }
}


export function uploadsReducer(
    state: IUploadState = initialState,
    action: UploadActions
): IUploadState {
    switch (action.type) {
        case UPDATE_UPLOAD_FILES:
            return {
                ...state,
                files: action.payload,
            };
        case SET_DETAILED_UPLOAD:
            return {
                ...state,
                detailedFile: action.payload,
            };
        case SET_UPLOAD_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case SET_UPLOAD_PAGE_COUNT:
            return {
                ...state,
                pageCount: action.payload,
            };
        case SET_SELECTED_FILE_IDS:
            return {
                ...state,
                selectedIds: action.payload,
            };
        case SET_DETAILED_FILE_PAGE:
            return {
                ...state,
                page: action.payload,
            };
        case SHOW_PREVIEW_PAGES:
            return {
                ...state,
                showPreviewPages: action.payload,
            };
        case SET_UPLOAD_POPUP_OPEN:
            return {
                ...state,
                isUploadPopupOpen: action.payload,
            };
        case UPDATE_UPLOAD_PACKAGE:
            return {
                ...state,
                package: action.payload,
            };
        case UPDATE_TECH_REPORT_TEXT:
            return {
                ...state,
                reportText: action.payload,
            };
        default:
            return state;
    }
}

