import styles from './App.module.scss';
import React from "react";
import {Provider} from "react-redux";
import {Route} from "react-router-dom";
import {ConnectedRouter} from "connected-react-router";
import {store} from "../store/store";
import {HomePage} from "../pages/HomePage/HomePage";
import {ROUTES} from "./Routes";
import {initStoreUtils} from "../store/utils";
import {history} from "../store/rootReducers";
import {AuthPageConnected} from "../pages/AuthPage/AuthPageConnected";
import {MonitoringPageConnected} from "../pages/MonitoringPage/MonitoringPageConnected";
import {DifferencePageConnected} from "../pages/DifferencePage/DifferencePageConnected";
import {InfoPage} from "../pages/Info/InfoPage";
import {PrivateRouteConnected} from "./PrivateRoute";
import {useChatScript, useMetricsScript} from "../hooks/hook";

initStoreUtils(store.dispatch, store.getState);

function App() {
    useChatScript();
    useMetricsScript();

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <div className={styles.App}>
                    <PrivateRouteConnected path={ROUTES.Home} exact component={HomePage}/>
                    <PrivateRouteConnected path={ROUTES.Info} exact component={InfoPage}/>
                    <Route path={ROUTES.Auth} exact component={AuthPageConnected}/>
                    <PrivateRouteConnected path={ROUTES.Monitoring} exact component={MonitoringPageConnected}/>
                    <PrivateRouteConnected path={ROUTES.Diff} component={DifferencePageConnected}/>
                </div>
            </ConnectedRouter>
        </Provider>

    );
}

export default App;
