import {useEffect} from 'react';

export const useChatScript = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript'
        script.src = 'https://pyrus.com/js/webwidget?id=cYiQ7a6YSAMwYeWUbSIaqXrF5zjjUZIh7rfVIWzXxUWu0gxSbG8WbyLkOdb1vhpxvpQrfgvXSS7q~zPXExqyCGf~3AE~fIZnqwM9QPYL-abobZhajG2SHY1rkU2vP9u4kaGgRg%3D%3D';
        script.async = true;

        document.body.append(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);
};

export const useMetrics63510862Script = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript'
    useEffect(() => {
        //@ts-ignore
        if(process.env.REACT_APP_TOGGLE_METRIC_63510862 || window.REACT_APP_TOGGLE_METRIC_63510862){

            script.src = '(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n' +
                '    m[i].l=1*new Date();\n' +
                '    for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}\n' +
                '    k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n' +
                '    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");\n' +
                '\n' +
                '    ym(63510862, "init", {\n' +
                '    clickmap:true,\n' +
                '    trackLinks:true,\n' +
                '    accurateTrackBounce:true\n' +
                '});';
            script.async = true;

            document.body.append(script);
        }

        return () => {
            document.body.removeChild(script);
        }
    }, []);
};
export const useMetrics63270799Script = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript'
    useEffect(() => {
        //@ts-ignore
        if(process.env.REACT_APP_TOGGLE_METRIC_63270799 || window.REACT_APP_TOGGLE_METRIC_63270799){

            script.src = '(function(m,e,t,r,i,k,a){m[i]=m[i]function(){(m[i].a=m[i].a[]).push(arguments)};\n' +
                '   m[i].l=1*new Date();\n' +
                '   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}\n' +
                '   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n' +
                '   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");\n' +
                '\n' +
                '   ym(63270799, "init", {\n' +
                '        clickmap:true,\n' +
                '        trackLinks:true,\n' +
                '        accurateTrackBounce:true,\n' +
                '        webvisor:true\n' +
                '   });';
            script.async = true;

            document.body.append(script);
        }

        return () => {
            document.body.removeChild(script);
        }
    }, []);
};
export const useMetricsScript = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript'
    useEffect(() => {
        //@ts-ignore
        if(process.env.REACT_APP_TOGGLE_METRIC || window.REACT_APP_TOGGLE_METRIC){
            script.src = '(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n' +
                '   m[i].l=1*new Date();\n' +
                '   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}\n' +
                '   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n' +
                '   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");\n' +
                '\n' +
                '   ym(63270799, "init", {\n' +
                '        clickmap:true,\n' +
                '        trackLinks:true,\n' +
                '        accurateTrackBounce:true,\n' +
                '        webvisor:true\n' +
                '   });';
            script.async = true;

            document.body.append(script);
        }

        return () => {
            document.body.removeChild(script);
        }
    }, []);
};

