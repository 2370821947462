import * as React from "react";
import {PopupElement} from "../../ducks/popup";
import styles from "./PopupDialog.module.scss";
import {Button, TextField} from "@mui/material";
import {CommonErrorText} from "./CommonErrorPopup/common-error-text";

export interface PopupDialogStateProps {
    popups: PopupElement[];
    reportText: string;
}

export interface PopupDialogDispatchProps {
    onPopupDelete: (id: string) => void;
    updateTechReportText: (value: string) => void;
}

export type PopupDialogProps = PopupDialogStateProps & PopupDialogDispatchProps;

export class PopupDialog extends React.Component<PopupDialogProps> {

    state = {
        message: ''
    }

    public render() {
        const popups = this.props.popups;
        return popups.length ? (
            <div className={styles.popupBack}>
                {popups.map((popupElem, index) => {
                    const actionHandler = () => {
                        if (popupElem.actionHandler) popupElem.actionHandler();
                        this.props.onPopupDelete(popupElem.id!);
                    };

                    const cancelHandler = () => {
                        if (popupElem.cancelHandler) popupElem.cancelHandler();
                        this.props.onPopupDelete(popupElem.id!);
                    };

                    const actionTitle = popupElem.actionTitle
                        ? popupElem.actionTitle
                        : 'Ок';
                    const cancelTitle = popupElem.cancelTitle
                        ? popupElem.cancelTitle
                        : "Отмена";
                    const actionButtonDisabled = popupElem.technicalSupport && this.props.reportText.trim() === ""

                    return (
                        <div id={`id_${popupElem.id}`} key={popupElem.id} className={styles.popupContainer}>
                            {popupElem.isDefaultError && <CommonErrorText/>}
                            {!popupElem.isDefaultError && <div className={styles.messageContainer}>
                                {popupElem.data.map(splitElem => {
                                    return (
                                        <div key={Math.random()} className={styles.message}>
                                            {splitElem}
                                        </div>
                                    );
                                })}
                                {
                                    popupElem.technicalSupport && <TextField
                                        sx={{
                                            marginTop: "20px",
                                            backgroundColor: "#FFF",
                                            width: '425px',
                                        }}
                                        id="standard-multiline-static"
                                        multiline
                                        minRows={2}
                                        maxRows={6}
                                        variant="outlined"
                                        onBlur={(event) => {
                                            const value = event.target.value;
                                            this.props.updateTechReportText(value ? value : '');
                                        }}
                                    />
                                }
                            </div>}

                                <div className={styles.buttonContainer}>
                                    {popupElem.actionVisible && (  <Button
                                        disabled={actionButtonDisabled}
                                        autoFocus={true}
                                        className={`${styles.buttonAction} ${actionButtonDisabled && styles.disabled}`}
                                        onClick={actionHandler}
                                        variant={'contained'}
                                    >
                                        {actionTitle}
                                    </Button>)}

                                    {popupElem.cancelVisible && (
                                        <Button
                                            variant={'contained'}
                                            autoFocus={true}
                                            className={styles.buttonCancel}
                                            onClick={cancelHandler}
                                        >
                                            {cancelTitle}
                                        </Button>
                                    )}

                                </div>
                        </div>
                    );
                })}
            </div>
        ) : (
            <></>
        );
    }
}
