import React, {useEffect, useRef, useState} from "react";
import {Button, Checkbox, FormControlLabel, IconButton} from "@mui/material";

import MyDropzone from "../dropzone/dropzone";
import {IDetailedDocumentPage, IDetailedFile, IFileFromInput,} from "../../ducks/upload";
import {PopupElement} from "../../ducks/popup";
import {asyncEncodeImageFileAsURL } from "../../helper/other";
import DropzoneFullScreen from "../dropzone/dropzoneFullScreen";

import styles from './UploadPopup.module.scss';

export interface IUploadPopupStateProps {
    files: IFileFromInput[];
    detailedFile: IDetailedFile | undefined;
    pageCount: number;
    isLoading: boolean;
    showPreviewPages: boolean;
    selectedIds: string[];
    page: IDetailedDocumentPage | undefined;
    isOpen: boolean;
}

export interface IUploadPopupDispatchProps {
    setUploadPageCount: (count: number) => void;
    setSelectedIds: (ids: string[]) => void;
    setDetailedFilePages: (page: IDetailedDocumentPage | undefined) => void;
    setUploadIsLoading: (isLoading: boolean) => void;
    setDetailedUpload: (uploadFile: IDetailedFile | undefined) => void;
    setUploads: (uploads: IFileFromInput[], usePrevUploads?: boolean) => void;
    deleteUploads: (uploads: IFileFromInput[]) => void;
    uploadFiles: (uploads: IFileFromInput[], usePrevUploads?: boolean) => void;
    popupPush: (popup: PopupElement) => void;
    setShowPreviewPages: (value: boolean) => void;
    setUploadPopupOpen: (value: boolean) => void;
    sendPackageToRecognition: (isPairSearchSkip: boolean) => void;
    onClearUploads: () => void;
    getUploads: () => void;
}

export type UploadPopupProps = IUploadPopupStateProps & IUploadPopupDispatchProps;
export function UploadPopup(props: UploadPopupProps) {
    const inputRef = useRef();
    useEffect(() => {
        props.getUploads();
    }, []);

    useEffect(() => {
        const {files, detailedFile} = props;
        try {
            if (detailedFile === undefined && files.length > 0) {
                const file = files[0]
                props.setDetailedUpload({
                    ...file,
                    index: 0,
                })

            }
        } catch {

        }
    }, [props.files])
    const [isDropHidden, setIsDropHidden] = useState(true);
    const [checked, setChecked] = useState(false);
    const {files, setUploads} = props;

    const handleFileDrop = async (acceptedFiles) => {
        setIsDropHidden(true);
        let newFiles = [];
        for (let i = 0; i < acceptedFiles.length; i++) {
            const file = acceptedFiles[i];
            await asyncEncodeImageFileAsURL(file)
                .then(async (base64res: any) => {
                    newFiles.push({
                        inputIndex: i,
                        id: `${new Date().getTime()}${i}`,
                        fileUrl: base64res,
                        fileName: file.name,
                        fileSize: file.size,
                    })
                });
        }
        props.uploadFiles([...newFiles].filter(elem => {
            const extension = elem.fileName.split('.').pop();
            return ['jpg', 'jpeg', 'png', 'pdf', 'xls', 'xlsx'].includes(extension)
        }), true)
    }

    return props.isOpen ? <div
        onDragEnter={(e) => {
            setIsDropHidden(false);
        }}
        className={styles.popupBackground}>
        {!isDropHidden && <DropzoneFullScreen
            files={[...props.files]}
            setFiles={props.setUploads}
            onDropHandler={handleFileDrop}
            onMouseLeaveEvent={() => {
                setIsDropHidden(true);
            }}
            onMouseOnEvent={() => {
                setIsDropHidden(false);
            }}
        />}
        <div
            className={`${styles.popupContainer}`}>
            <IconButton
                onClick={() => {
                    props.setUploadPopupOpen(false)
                }}
                className={styles.closeIcon}
            >
            </IconButton>
            <div className={`${styles.uploadContainer}`}>
                <MyDropzone
                    isEmpty={[...props.files].length === 0}
                    files={[...props.files]}
                    setFiles={props.setUploads}
                    onDropHandler={handleFileDrop}
                />
            </div>
            <div className={styles.bottomPart}>
                <FormControlLabel control={<Checkbox
                    disabled={props.files.length < 2}
                    sx={{
                        color: `#F57B20`,
                        '&.Mui-checked': {
                            color: `#F57B20`,
                        },
                        fontSize: '14px',
                    }}
                    checked={checked}
                    onChange={((event, checked1) => setChecked(checked1))}
                />} label={<span className={styles.label}>Сравнить сразу</span>}/>
                <div className={styles.buttonsContainer}>
                    <input
                        multiple={true}
                        maxLength={255}
                        hidden={true}
                        type="file"
                        accept="image/jpeg,image/png,application/pdf,image/tiff,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        ref={inputRef}
                        value={''}
                        onChange={(event) => {
                            const files = event.target.files;
                            const newFiles = [];
                            for (let i = 0; i < files.length; i++) {
                                newFiles.push(files[i]);
                            }
                            handleFileDrop(newFiles);
                        }}
                    />
                    <Button
                        variant={'contained'}
                        onClick={() => {
                            //@ts-ignore
                            inputRef.current.click()
                        }}
                        className={styles.uploadButton}
                    >
                        Загрузить еще
                    </Button>
                    <Button
                        variant={'contained'}
                        disabled={!files.length}
                        onClick={() => {
                            props.popupPush({
                                actionTitle: "Удалить",
                                actionVisible: true,
                                data: ["Вы действительно хотите удалить все загруженные страницы?"],
                                actionHandler: () => {
                                    setUploads([]);
                                    props.setDetailedUpload(undefined);
                                    props.onClearUploads();
                                },
                                cancelVisible: true,
                            });
                        }}
                        className={styles.cancelButton}
                    >
                        Очистить
                    </Button>
                    <Button
                        variant={'contained'}
                        onClick={() => {
                            props.sendPackageToRecognition(checked && props.files.length >= 2);
                        }}
                        disabled={!files.length}
                        className={styles.uploadButton}
                    >
                        Отправить на обработку
                    </Button>
                </div>
            </div>
        </div>
    </div> : <></>
}
