import {Thunk, TypedAction} from "../store/types";
import {popupPush, spinnerMinusStatus, spinnerPlusStatus} from "./popup";
import {IDifferenceMock} from "../components/differenceDocument/DifferenceDocument";
import {
    defaultFilter,
    getMonitoringApiObj,
    IDetailedDocument,
    IDiscrepancyStatus,
    IDocumentImage,
    IDocumentImageId,
    IMonitoringFilter,
    IMonitoringItem,
    INotRecognizedPackage,
    IUpdateDocument,
    IUpdateDocumentList
} from "../api/monitoringApi";
import {selectDetailedDocuments, selectMonitoringList, setStoragePage} from "../selectors/monitoring";
import {getUploadApiObj, IConvertedImage} from "../api/uploadApi";
import {getBalanceApiObj} from "../api/balanceApi";
import {push} from "connected-react-router";
import {ROUTES} from "../app/Routes";
import {IBalance, SET_USER_BALANCE} from "./auth";

export enum MonitoringStatuses {
    RECOGNITION = null,
    ACTS_NOT_RECOGNISED = 1,
    ACTS_MATCH = 12,
    ACTS_HAVE_DIFF = 13,
    WAITING_FOR_PAIR = 11,
    ACTS_PASSED = 14,
    ACTS_REJECTED = 15,
    DELETED = 8,
}

export enum SortDirections {
    ASC = "asc",
    DESC = "desc"
}

export enum PackageStatuses {
    RECOGNITION = 3,
    RECOGNIZED = 9
}

export interface IDifferencePageScaleState {
    leftScale: number;
    rightScale: number;
}

export const MONITORING_STATUSES = {
    0: "Распознавание",
    1: "Акт не распознан",
    2: "Два акта сопоставлены и проверены. Замечаний нет",
    3: "Два акта сопоставлены и проверены. Есть расхождения",
    4: "Загруженный акт ожидает пару",
    5: "Обработка завершена. Акт принят",
    6: "Обработка завершена. Акт отклонен",
    7: "Удален"
}

const SET_LOADS = "MONITORING/SET_LOADS";
const SET_SEARCH_OPTIONS = "MONITORING/SET_SEARCH_OPTIONS";
const SET_DETAILED_DIFF = "MONITORING/SET_DETAILED_DIFF";
const SET_COMMENTS = "MONITORING/SET_COMMENTS";
const SET_COMMENT_INPUT_SHOWN = "MONITORING/SET_COMMENT_INPUT_SHOWN";
const SET_DETAILED_DOCUMENTS = "MONITORING/SET_DETAILED_DOCUMENTS";
const SET_DOCUMENT_IMAGES_RIGHT = "MONITORING/SET_DOCUMENT_IMAGES_RIGHT";
const SET_DOCUMENT_IMAGES_LEFT = "MONITORING/SET_DOCUMENT_IMAGES_LEFT";
const SET_DETAILED_DOCUMENT_ID_LEFT = "MONITORING/SET_DETAILED_DOCUMENT_ID_LEFT";
const SET_DETAILED_DOCUMENT_ID_RIGHT = "MONITORING/SET_DETAILED_DOCUMENT_ID_RIGHT";
const SET_LEFT_SCALE = "MONITORING/SET_LEFT_SCALE";
const SET_RIGHT_SCALE = "MONITORING/SET_RIGHT_SCALE";
const SET_IS_TRACK_DISABLED = "MONITORING/SET_IS_TRACK_DISABLED";
const SET_SORT_TYPE = "MONITORING/SET_SORT_TYPE";
const SET_LIST_PAGE = "MONITORING/SET_LIST_PAGE";
const SET_TABLE_CHECKBOX_SHOW = "MONITORING/SET_TABLE_CHECKBOX_SHOW";
const UPDATE_SELECTED_DOC_IDS = "MONITORING/UPDATE_SELECTED_DOC_IDS";
const SET_PREVIEW_MODAL_OPEN = "MONITORING/SET_PREVIEW_MODAL_OPEN";
const SET_IMAGES_LOADING = "MONITORING/SET_IMAGES_LOADING";

export type MonitoringActions =
    | TypedAction<typeof SET_LOADS, IMonitoringItem[]>
    | TypedAction<typeof SET_COMMENTS, IDifferenceMock[]>
    | TypedAction<typeof SET_DETAILED_DOCUMENTS, IDetailedDocument[]>
    | TypedAction<typeof SET_DOCUMENT_IMAGES_RIGHT, IDocumentImage[]>
    | TypedAction<typeof SET_DOCUMENT_IMAGES_LEFT, IDocumentImage[]>
    | TypedAction<typeof SET_DETAILED_DOCUMENT_ID_RIGHT, string | undefined>
    | TypedAction<typeof SET_LEFT_SCALE, number>
    | TypedAction<typeof SET_RIGHT_SCALE, number>
    | TypedAction<typeof SET_DETAILED_DOCUMENT_ID_LEFT, string | undefined>
    | TypedAction<typeof SET_DETAILED_DIFF, string | undefined>
    | TypedAction<typeof SET_IS_TRACK_DISABLED, boolean>
    | TypedAction<typeof SET_SORT_TYPE, string | undefined>
    | TypedAction<typeof SET_SEARCH_OPTIONS, string[]>
    | TypedAction<typeof SET_COMMENT_INPUT_SHOWN, boolean>
    | TypedAction<typeof SET_PREVIEW_MODAL_OPEN, boolean>
    | TypedAction<typeof SET_TABLE_CHECKBOX_SHOW, boolean>
    | TypedAction<typeof SET_IMAGES_LOADING, boolean>
    | TypedAction<typeof UPDATE_SELECTED_DOC_IDS, string[]>
    | TypedAction<typeof SET_LIST_PAGE, number>;

export interface IMonitoringState {
    list: IMonitoringItem[];
    selectedDiff: string | undefined;
    leftScale: number;
    rightScale: number;
    comments: IDifferenceMock[];
    isCommentInputShown: boolean;
    detailedDocumentIdLeft: string;
    detailedDocumentIdRight: string;
    detailedDocuments: IDetailedDocument[];
    leftDetailedImages: IDocumentImage[];
    rightDetailedImages: IDocumentImage[];
    isTrackDisabled: boolean;
    searchOptions: string[];
    sortType: SortDirections | undefined;
    listPage: number;
    checkboxShow: boolean;
    docIds: string[];
    previewModalOpen: boolean;
    imagesLoading: boolean;
}

export enum Directions {
    Right = 'right',
    Left = 'left',
}

export const initialState: IMonitoringState = {
    list: [],
    selectedDiff: undefined,
    comments: [],
    isCommentInputShown: false,
    detailedDocuments: [],
    leftDetailedImages: [],
    rightDetailedImages: [],
    detailedDocumentIdLeft: undefined,
    detailedDocumentIdRight: undefined,
    leftScale: 1,
    rightScale: 1,
    isTrackDisabled: false,
    searchOptions: [],
    sortType: SortDirections.DESC,
    listPage: 0,
    checkboxShow: false,
    docIds: [],
    previewModalOpen: false,
    imagesLoading: false,
}

export function setDetailedDiff(diff: string) {
    return dispatch => {
        dispatch({type: SET_DETAILED_DIFF, payload: diff});
    }
}

export function setPreviewModalOpen(flag: boolean) {
    return dispatch => {
        dispatch({type: SET_PREVIEW_MODAL_OPEN, payload: flag});
    }
}

export function setImagesLoading(flag: boolean) {
    return dispatch => {
        dispatch({type: SET_IMAGES_LOADING, payload: flag});
    }
}

export function updateSelectedDocIds(docIds: string[]) {
    return dispatch => {
        dispatch({type: UPDATE_SELECTED_DOC_IDS, payload: docIds});
    }
}

export function setCheckboxShow(value: boolean) {
    return dispatch => {
        if(!value) dispatch(onClearDifferencePage());
        dispatch({type: SET_TABLE_CHECKBOX_SHOW, payload: value});
    }
}

export function setSortType(type: SortDirections | undefined) {
    return dispatch => {
        dispatch({type: SET_SORT_TYPE, payload: type});
    }
}

export function setSearchOptions(options: string[]) {
    return dispatch => {
        dispatch({type: SET_SEARCH_OPTIONS, payload: options});
    }
}

export function setDifferenceStatus(index: number, status: IDiscrepancyStatus): Thunk {
    return async (dispatch, getState) => {
        const detailedDocuments = selectDetailedDocuments(getState());
        const docLeft = detailedDocuments[0];
        docLeft.docflowDocumentData.extendData.discrepancyData[index].status = [status];
        detailedDocuments[0] = {...docLeft};
        const newProps: IUpdateDocumentList[] = [
            {
                docId: detailedDocuments[0].docId,
                discrepancy: detailedDocuments[0].docflowDocumentData.extendData.discrepancyData,
                status: detailedDocuments[0].docflowDocumentData.statusId,
                comment: detailedDocuments[0].docflowDocumentData.comment,
            },
            detailedDocuments[1] && {
                docId: detailedDocuments[1].docId,
                discrepancy: detailedDocuments[0].docflowDocumentData.extendData.discrepancyData,
                status: detailedDocuments[1].docflowDocumentData.statusId,
                comment: detailedDocuments[1].docflowDocumentData.comment,
            },
        ];
        dispatch({type: SET_DETAILED_DOCUMENTS, payload: [...detailedDocuments]});
        await getMonitoringApiObj()
            .updateDocumentStatus(newProps)
            .then((res) => {
            })
            .catch((error) => {
                console.log("setDifferenceStatus", error)
            })
            .finally(() => {
            })
    }
}

export function getStatusName(name: MonitoringStatuses): string {
    const status = MONITORING_STATUSES[name];
    return status ? status : "";
}

export function getRecognitionActs(filter: IMonitoringFilter = defaultFilter, isSearch: boolean = false): Thunk {

    const getFilteredActs = async dispatch => {
        const newFilter: IMonitoringFilter = {
            ...filter,
            filter: {...filter.filter, status: [...filter.filter.status]}
        };
        let newStatuses = [];
        if (filter.filter.status.includes(MonitoringStatuses.ACTS_NOT_RECOGNISED)) {
            newStatuses.push(null);
        }
        if (filter.filter.status.includes(MonitoringStatuses.RECOGNITION)) {
            newStatuses = [...newStatuses, 11, 12, 13, 14, 15, 8, null];
        }
        newStatuses = [...newStatuses, ...filter.filter.status.filter(elem => elem !== null && elem !== 1)]

        // @ts-ignore
        newStatuses = [...new Set(newStatuses)];
        await getMonitoringApiObj()
            .getList({...newFilter, filter: {...filter.filter, status: newStatuses, isAllUnrecognized: false, packageStatus: [3, 9]}})
            .then(res => res.json())
            .then((res) => {
                try {
                    let acts: IMonitoringItem[] = res;
                    let filteredActs: IMonitoringItem[] = [];
                    if (filter.filter.status.includes(MonitoringStatuses.RECOGNITION)) {
                        filteredActs = [...filteredActs, ...acts.filter(elem => elem.packageStatusId === PackageStatuses.RECOGNITION)];
                    }
                    if (filter.filter.status.includes(MonitoringStatuses.ACTS_NOT_RECOGNISED)) {
                        filteredActs = [...filteredActs, ...acts.filter(elem => (elem.packageStatusId === PackageStatuses.RECOGNIZED && !elem.docId))];
                    }
                    const otherFilters = [...filter.filter.status.filter(elem => elem !== 1 || elem !== null)];
                    filteredActs = [
                        ...filteredActs,
                        ...acts.filter(elem => elem.docflowDocumentData && elem.docId && elem.packageStatusId === PackageStatuses.RECOGNIZED)
                            .filter(elem => otherFilters.includes(elem.docflowDocumentData.statusId))
                    ];
                    if (filter.filter.status.length === 0) {
                        filteredActs = [...acts]
                    }
                    if (filteredActs.length > 0) {
                        const filteredOptions = filteredActs.filter(elem => elem.docflowDocumentData && elem.docflowDocumentData.extendData).map(elem => ({
                            organisation: elem.docflowDocumentData.extendData.organization,
                            contrAgent: elem.docflowDocumentData.extendData.contractor
                        }));
                        dispatch({
                            type: SET_SEARCH_OPTIONS,
                            payload: [
                                ...filteredOptions.map((option, index) => {
                                    let value = option.contrAgent;
                                    return value
                                }),
                                ...filteredOptions.map((option, index) => {
                                    let value = option.organisation;
                                    return value
                                }),]
                        })
                    }
                    dispatch({type: SET_LOADS, payload: filteredActs});
                } catch (e) {
                    console.log("e", e)
                }
            })
            .catch((error) => {
                dispatch({type: SET_LOADS, payload: []});
                console.log("sendPackageToRecognition", error)
            })
            .finally(() => {
                dispatch(spinnerMinusStatus())
            })
    }

    //@ts-ignore
    if (process.env.REACT_APP_EXTERNAL_TOKEN_AUTH) {
        return async (dispatch, getState) =>
            getFilteredActs(dispatch)
    } else {
        return async (dispatch, getState) => {
            !isSearch && dispatch(spinnerPlusStatus());
            await getBalanceApiObj()
                .getAll()
                .then(response => response.json())
                .then(async response => {
                    const balance: IBalance = response;
                    dispatch({
                        type: SET_USER_BALANCE,
                        payload: balance.pagesLeft ? balance.pagesLeft : 0,
                    })
                    await getFilteredActs(dispatch)
                })
                .catch((err) => {
                    if ([401, 403].includes(err.status)) {
                        dispatch(push(ROUTES.Auth))
                    }
                    console.log('get balance error', err)
                })
            dispatch(spinnerMinusStatus())
        }
    }
}

export function getActsForHandleRecognition() {
    return async (dispatch, getState) => {
        const acts = selectMonitoringList(getState());
        dispatch({
            type: SET_LOADS,
            payload: acts.filter(elem => elem.docflowDocumentData && elem.docId && elem.packageStatusId === PackageStatuses.RECOGNIZED).filter(acts => acts.docflowDocumentData.statusId === MonitoringStatuses.WAITING_FOR_PAIR)
        });
    }
}

export function updateDocumentStatus(documentId: string, props: IUpdateDocument, helpForm: boolean = false): Thunk {
    return async (dispatch, getState) => {
        const documents = selectDetailedDocuments(getState());
        const newProps: IUpdateDocumentList[] = [
            {
                docId: documents[0].docId,
                discrepancy: documents[0].docflowDocumentData.extendData.discrepancyData,
                status: props.status,
                comment: props.comment,
            },
            documents[1] && {
                docId: documents[1].docId,
                discrepancy: documents[0].docflowDocumentData.extendData.discrepancyData,
                status: props.status,
                comment: props.comment,
            },
        ];
        dispatch(spinnerPlusStatus())
        await getMonitoringApiObj()
            .updateDocumentStatus(newProps)
            .then((res) => {

            })
            .catch((error) => {
                console.log("updateDocumentStatus", error)
            })
            .finally(() => {
                dispatch(spinnerMinusStatus())
            })
        dispatch(spinnerMinusStatus())
    }
}

export function getDetailedDocumentsImages(documentId: string, direction: Directions = Directions.Left): Thunk {
    return async (dispatch, getState) => {
        await getMonitoringApiObj()
            .getDocumentImage(documentId)
            .then(res => res.json())
            .then((res) => {
                const imagesRawArray: IDocumentImageId[] = res;
                const images: IDocumentImage[] = imagesRawArray.map(elem => (
                    {
                        fileUrl: `data:image/jpg;base64,${elem.finalImgBase64}`
                    }
                ))
                if (direction === Directions.Right) {
                    dispatch({
                        type: SET_DOCUMENT_IMAGES_RIGHT,
                        payload: images,
                    })
                } else {
                    dispatch({
                        type: SET_DOCUMENT_IMAGES_LEFT,
                        payload: images,
                    })
                }
            })
            .catch((error) => {
                console.log("getDetailedDocumentsImages", error)
            })
            .finally(() => {
                dispatch(spinnerMinusStatus());
                dispatch(setImagesLoading(false));
            })
        dispatch(spinnerMinusStatus())
    }
}

export function getUnrecognizedPackagesImages(documentId: number, direction: Directions = Directions.Left): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerPlusStatus());
        await getMonitoringApiObj()
            .getUnrecognizedPackage(documentId)
            .then(res => res.json())
            .then(async (res) => {
                const unrecognizedPackage: INotRecognizedPackage[] = res;
                let images = [];
                let promiseArray = [];
                dispatch(spinnerPlusStatus());
                unrecognizedPackage.forEach((elem) => {
                    promiseArray.push(getUploadApiObj()
                        .getImageById(elem.jsonContent.pages.id)
                        .then(response => response.json())
                        .then(response => {
                            const imageBase64: IConvertedImage = response;
                            images.push(imageBase64.rawBase64);
                        })
                    )

                })
                await Promise.allSettled(promiseArray).then((results) => {
                    let errors = [];
                    results.filter(result => result.status === 'rejected').forEach((elem: PromiseRejectedResult) => {
                        results.filter(result => result.status === 'rejected').forEach((elem: PromiseRejectedResult) => {
                            let err = 'Произошла ошибка';
                            try {
                                let message = JSON.parse(elem.reason.message);
                                err = message.message
                            } catch {

                            }

                            errors.push(err)
                        })
                    })

                    errors.length > 0 && dispatch(popupPush({
                        actionTitle: "Хорошо",
                        actionVisible: true,
                        data: errors,
                        actionHandler: () => {
                        },
                        cancelVisible: false,
                        isDefaultError: true,
                    }))
                    dispatch({
                        type: SET_DOCUMENT_IMAGES_LEFT,
                        payload: images.map(elem => (
                            {
                                fileUrl: `data:image/jpg;base64,${elem}`
                            }))
                    })
                    dispatch(spinnerMinusStatus())
                })
            })
            .catch((error) => {
                console.log("getUnrecognizedPackagesImages", error)
                dispatch(popupPush({
                    actionTitle: "Хорошо",
                    actionVisible: true,
                    isDefaultError: true,
                    data: ["Произошла ошибка"],
                    actionHandler: () => {
                    },
                    cancelVisible: false,
                }))
            })
            .finally(() => {
                dispatch(spinnerMinusStatus())
            })
        dispatch(spinnerMinusStatus())
    }
}

export function deleteUnrecognizedPackagesImages(packageId: number): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerPlusStatus());
        await getMonitoringApiObj()
            .getUnrecognizedPackage(packageId)
            .then(res => res.json())
            .then(async (res) => {
                const unrecognizedPackage: INotRecognizedPackage[] = res;
                dispatch(spinnerPlusStatus());
                await getMonitoringApiObj()
                    .deleteUnrecognizedPackage(packageId, {
                        imgId: unrecognizedPackage.map((elem) => {
                            return elem.jsonContent.pages.id;
                        }),
                    })
                    .then(() => {
                    })
                    .finally(() => {
                        dispatch(spinnerMinusStatus())
                    })
            })
            .catch((error) => {
                console.log("deleteUnrecognizedPackagesImages", error)
            })
            .finally(() => {
                dispatch(spinnerMinusStatus())
            })
        dispatch(spinnerMinusStatus())
    }
}

export function getImagesForModalPreview(docId: string): Thunk {
    return async (dispatch, getState) => {
        dispatch(setImagesLoading(true));
        dispatch({
            type: SET_DETAILED_DOCUMENT_ID_LEFT,
            payload: docId,
        });
        dispatch(getDetailedDocumentsImages(docId));
    }
}

export function handleCreateDocsPair(docIds: string[]): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerPlusStatus());
        await getMonitoringApiObj()
            .handlePairDocuments(docIds[0], {
                docId: docIds[1]
            })
            .then(() => {
                dispatch(onClearDifferencePage());
                dispatch(setPreviewModalOpen(false));
                dispatch(setCheckboxShow(false));
            })
            .catch((err) => {
                console.log("create pair error", err)
            })
            .finally(() => {
                dispatch(onClearDifferencePage());
                dispatch(spinnerMinusStatus());
            })
    }
}

export function getDetailedDocuments(docId: string): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerPlusStatus());
        dispatch({
            type: SET_DETAILED_DOCUMENT_ID_LEFT,
            payload: docId,
        });
        await getMonitoringApiObj()
            .getDetailedDocumentByDocId(docId)
            .then(res => res.json())
            .then(async (res) => {
                const detailedDocumentLeft: IDetailedDocument = res;
                dispatch(getDetailedDocumentsImages(docId));
                if(detailedDocumentLeft
                    && detailedDocumentLeft.docflowDocumentData
                    && detailedDocumentLeft.docflowDocumentData.extendData
                    && detailedDocumentLeft.docflowDocumentData.extendData.discrepancyData
                ){
                    detailedDocumentLeft.docflowDocumentData.extendData.discrepancyData = detailedDocumentLeft.docflowDocumentData.extendData.discrepancyData.map((elem, index) => ({...elem, apiIndex: index}))
                }
                dispatch({
                    type: SET_DETAILED_DOCUMENTS,
                    payload: [{...detailedDocumentLeft}],
                })
                await getMonitoringApiObj()
                    .getDetailedDocumentByDocId(detailedDocumentLeft.docflowDocumentData.pairDocumentId)
                    .then(response => response.json())
                    .then(response => {
                        const detailedDocumentRight: IDetailedDocument = response;
                        dispatch(getDetailedDocumentsImages(detailedDocumentRight.docId, Directions.Right));

                        if(detailedDocumentRight
                            && detailedDocumentRight.docflowDocumentData
                            && detailedDocumentRight.docflowDocumentData.extendData
                            && detailedDocumentRight.docflowDocumentData.extendData.discrepancyData
                        ){
                            detailedDocumentRight.docflowDocumentData.extendData.discrepancyData = detailedDocumentRight.docflowDocumentData.extendData.discrepancyData.map((elem, index) => ({...elem, apiIndex: index}))
                        }

                        dispatch({
                            type: SET_DETAILED_DOCUMENTS,
                            payload: [detailedDocumentLeft, {...detailedDocumentRight}],
                        })
                    })
            })
            .catch((error) => {
                console.log("getDetailedDocuments", error)
            })
            .finally(() => {
                dispatch(spinnerMinusStatus());
            })
        dispatch(spinnerMinusStatus());
    }
}

export function onClearDifferencePage(): Thunk {
    return (dispatch, getState) => {
        dispatch({type: SET_DETAILED_DOCUMENT_ID_RIGHT, payload: undefined});
        dispatch({type: SET_DETAILED_DOCUMENT_ID_LEFT, payload: undefined});
        dispatch({type: UPDATE_SELECTED_DOC_IDS, payload: []});
        dispatch({type: SET_DETAILED_DOCUMENTS, payload: []});
        dispatch({type: SET_DOCUMENT_IMAGES_LEFT, payload: []});
        dispatch({type: SET_DOCUMENT_IMAGES_RIGHT, payload: []});
        dispatch({type: SET_DETAILED_DIFF, payload: undefined});
    }
}

export function setCommentInputShown(flag: boolean): Thunk {
    return async dispatch => {
        dispatch({type: SET_COMMENT_INPUT_SHOWN, payload: flag});
    };
}

export function setDetailedDocumentLeftId(docId: string): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_DETAILED_DOCUMENT_ID_LEFT,
            payload: docId,
        });
    };
}

export function setLeftScale(leftScale: number): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_LEFT_SCALE,
            payload: leftScale,
        });
    };
}

export function setRightScale(rightScale: number): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_RIGHT_SCALE,
            payload: rightScale,
        });
    };
}

export function setIsTrackDisabled(flag: boolean): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_IS_TRACK_DISABLED,
            payload: flag,
        });
    };
}

export function setListPage(pageNumber: number): Thunk {
    return async dispatch => {
        setStoragePage(pageNumber);
        dispatch({type: SET_LIST_PAGE, payload: pageNumber});
    };
}

export function monitoringReducer(
    state: IMonitoringState = initialState,
    action: MonitoringActions
): IMonitoringState {
    switch (action.type) {
        case SET_LOADS:
            return {
                ...state,
                list: action.payload,
            };
        case SET_DETAILED_DIFF:
            return {
                ...state,
                selectedDiff: action.payload,
            }
        case SET_COMMENTS:
            return {
                ...state,
                comments: action.payload,
            }
        case SET_COMMENT_INPUT_SHOWN:
            return {
                ...state,
                isCommentInputShown: action.payload,
            }
        case SET_DETAILED_DOCUMENTS:
            return {
                ...state,
                detailedDocuments: action.payload,
            }
        case SET_DOCUMENT_IMAGES_RIGHT:
            return {
                ...state,
                rightDetailedImages: action.payload,
            }
        case SET_DOCUMENT_IMAGES_LEFT:
            return {
                ...state,
                leftDetailedImages: action.payload,
            }
        case SET_DETAILED_DOCUMENT_ID_LEFT:
            return {
                ...state,
                detailedDocumentIdLeft: action.payload,
            }
        case SET_DETAILED_DOCUMENT_ID_RIGHT:
            return {
                ...state,
                detailedDocumentIdRight: action.payload,
            }
        case SET_LEFT_SCALE:
            return {
                ...state,
                leftScale: action.payload,
            }
        case SET_RIGHT_SCALE:
            return {
                ...state,
                rightScale: action.payload,
            }
        case  SET_IS_TRACK_DISABLED:
            return {
                ...state,
                isTrackDisabled: action.payload,
            }
        case  SET_SEARCH_OPTIONS:
            return {
                ...state,
                searchOptions: action.payload,
            }
        case  SET_SORT_TYPE:
            return {
                ...state,
                sortType: action.payload,
            }
        case SET_LIST_PAGE:
            return {...state, listPage: action.payload}
        case UPDATE_SELECTED_DOC_IDS:
            return {...state, docIds: action.payload}
        case SET_TABLE_CHECKBOX_SHOW:
            return {...state, checkboxShow: action.payload}
        case SET_PREVIEW_MODAL_OPEN:
            return {...state, previewModalOpen: action.payload}
        case SET_IMAGES_LOADING:
            return {...state, imagesLoading: action.payload}
        default:
            return state;
    }
}
